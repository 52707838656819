var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"dfb8649c043c5f646ab40ab501ac0ddeebc37255"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: "https://21e41840b0684e2b94f1587c2ae6ddb5@o72281.ingest.sentry.io/4505509346017280",
  tracesSampleRate: 0
});